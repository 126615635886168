<template>
  <div class="container">
    <div class="mt-2">
      <h5 v-if="order_type_id != 11 || (order_type_id == 11 && stipendStudents.length == 0)" class="mb-3">Добавление студентов к параграфу</h5>

      <div v-if="order_type_id == 10">
        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Курс</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="studyCourse">
              <option v-for="(sCourse, sCourseIndex) in [{value: 0, name:'Выберите курс'},...studyCourses]"
                      :key="sCourseIndex"
                      :value="sCourse.value">
                {{ sCourse.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Уровень обучения</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="studyLevelId">
              <option
                  v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...docOrdersStudents_form.studyLevels]"
                  :value="item.id"
                  :key="index">{{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-4" v-if="studyCourse&&studyLevelId">
          <label class="col-md-3 col-form-label">Образовательная программа</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="educationProgramId">
              <option
                  v-for="(item, index) in [{id: 0, education_speciality_name: 'Выберите ОП', language_name: 'язык', admission_year:'год' }, ...docOrdersStudents_form.educationPrograms]"
                  :value="item.id"
                  :key="index">
                {{ item.education_speciality_code }} {{ item.education_speciality_name }} -
                {{ item.language_name }} - {{ item.admission_year }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-4" v-if="educationProgramId != 0">
          <div class="col-md-3">Группы</div>
          <div class="col-md-9" v-if="!loadingGroup">
            <div v-if="docOrdersStudents_form.studentGroups.length">
              <select class="form-control form-select" v-model="groupId">
                <option
                    v-for="(item, index) in [{id: 0, name: 'Выберите группу'}, ...docOrdersStudents_form.studentGroups]"
                    :value="item.id"
                    :key="index">{{ item.name }}
                </option>
              </select>
            </div>
            <div v-else class="text-danger">
              В данной образовательной программе нет групп
            </div>
          </div>
        </div>

        <div class="mt-4 text-center">
          <Button label="Заполнить" class="p-button-success" @click="selectGroup" style="margin-right: 5px"
                  :disabled="!groupId"/>
          <Button label="Очистить" class="p-button-warning" @click="clearStudents"
                  :disabled="!filteredStudents.length"/>
        </div>

        <DataTable class="mt-4" v-if="filteredStudents.length > 0" :value="filteredStudents"
                   stripedRows responsiveLayout="scroll">
          <template>
            <Column>
              <template #body="{data}">
                <input class="form-check-input" type="checkbox"
                       :value="data.id"
                       v-model="checked_students">
              </template>
            </Column>
          </template>

          <template>
            <Column header="Из группы">
              <template #body="{data}">
                {{ data.group_name }}
              </template>
            </Column>
          </template>

          <Column field="barcode" header="Баркод"></Column>
          <Column header="ФИО">
            <template #body="{data}">
              {{ data.last_name }} {{ data.first_name }} {{ data.middle_name }}
            </template>
          </Column>
        </DataTable>

        <div class="d-flex justify-content-center align-items-center" v-if="checked_students.length > 0">
          <Button class="mt-3" label="Отправить" @click="sendStudentsForTransfer($event)"></Button>
        </div>
      </div>

      <div v-else>
        <Dropdown v-if="order_type_id != 11 || (order_type_id == 11 && stipendStudents.length == 0)"
            v-model="searchData" editable :options="availableStudents"
            optionLabel="first_name"
            placeholder="Баркод, Имя, Фамилия" class="col-md-6 col-12" @change="e => searchData = ''">
          <template #option="slotProps">
            <div class="flex align-items-center" @click="chooseStudent(slotProps.option.student_id)">
              {{ slotProps.option.barcode }} <b>|</b> {{ slotProps.option.last_name }}
              {{ slotProps.option.first_name }} <b>|</b> {{ slotProps.option.education_program_name }}
            </div>
          </template>
        </Dropdown>

        <div v-if="chosenStudents.length > 0" class="my-2">
          <div v-if="order_type_id == 11">
            <div class="form-group row mt-4">
              <label for="semester" class="col-md-3 col-form-label">Семестр</label>
              <div class="col-md-9">
                <select class="form-control form-select" id="semester"
                        v-model="chosenSemester">
                  <option
                    v-for="(item, index) in [{id: null, name:'Выберите семестр'},...semesters]"
                    :value="item.id"
                    :key="index">{{ item.name }}
                  </option>
                </select>
              </div>
            </div>


            <div class="form-group row mt-4">
              <label for="foreign_university_id" class="col-md-3 col-form-label">ВУЗ (партнер)</label>
              <div class="col-md-9">
                <select class="form-control form-select" id="foreign_university_id"
                        v-model="chosenUniversity">
                  <option
                    v-for="(item, index) in [{id: 0, university_name:'Выберите университет'},...docOrdersStudents_form.foreignUniversity]"
                    :value="item.id"
                    :key="index">{{ item.university_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

        </div>

        <div class="mt-3" v-if="chosenStudents.length > 0">
          <h5>Выбранные студенты:</h5><br>
          <div class="d-flex align-items-center mb-1" v-for="(student, index) in chosenStudents" :key="index">
            <span class="me-2">{{ student.last_name }} {{ student.first_name }} {{ student.barcode }} {{ student.student_group_name }}</span>
            <Button class="p-button-danger" @click="removeFromStudentData(student.student_id)">Удалить</Button>
          </div>
          <Button :disabled="order_type_id == 11 && (chosenSemester == 0 || chosenUniversity == 0)"
                  class="my-2" label="Сохранить изменения" @click="confirm($event)"></Button><br>
          <small v-if="order_type_id == 11 && (chosenSemester == 0 || chosenUniversity == 0)">
            Кнопка станет кликабельна когда будут выбраны Семестр и ВУЗ (партнер)</small>
        </div>
        <ConfirmPopup/>
      </div>
    </div>
    <div class="mt-5" v-if="stipendStudents.length > 0">
      <h5 class="mb-3 text-center">Список студентов прикрепленных к параграфу</h5>
      <DataTable :value="stipendStudents">
        <Column field="barcode" header="Баркод"/>
        <Column field="last_name" header="Фамилия"/>
        <Column field="first_name" header="Имя"/>
        <Column field="study_course" header="Курс"/>
        <Column v-if="order_type_id == 11" field="semester" header="Семестра"/>
        <Column v-if="order_type_id == 11" field="university_name" header="ВУЗ (партнер)"/>
      </DataTable>

      <div class="my-3 text-center">
        <div v-if="isVisibleChangeCourse">
          <Button label="Сохранить" :loading="loadingChangeCourse" @click="changeCourse"/>
        </div>
        <div v-else>
          Данные сохранены
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import httpClient from "@/services/http.service";

export default {
  data() {
    return {
      order_type_id: null,

      availableStudents: [],
      chosenStudents: [],
      searchData: '',
      barcode: null,

      studyLevelId: 0,
      studyCourse: 0,
      educationProgramId: 0,
      groupId: 0,
      loadingGroup: false,
      filteredStudents: [],
      checked_students: [],
      selected_student: {},

      semesters: [],
      chosenSemester: null,
      chosenUniversity: 0,
      successMessage: 'Действие успешно выполнено!',
      errorMessage: 'Что-то пошло не так!',

      studyCourses: [
        {
          value: 1,
          name: '1'
        },
        {
          value: 2,
          name: '2'
        },
        {
          value: 3,
          name: '3'
        },
        {
          value: 4,
          name: '4'
        },
      ],
      loadingChangeCourse: false,
    }
  },
  computed: {
    ...mapState('docOrdersStudents', ['stipendStudents', 'docOrdersStudents_form']),
    ...mapState('formationEducationProgram', ['formationEducationProgram_form']),
    isVisibleChangeCourse(){
      return this.stipendStudents.some(i => i.order_to !== i.study_course)
    }
  },
  watch: {
    studyCourse() {
      this.getEducationPrograms()
    },
    studyLevelId() {
      this.getEducationPrograms()
    },
    async educationProgramId(id) {
      this.loadingGroup = true
      await this.GET_STUDENTS_BY_EDUCATION_PROGRAM_ID(id)
      this.clearStudents()
      this.loadingGroup = false
    },

    async searchData(newVal, oldVal) {
      newVal > oldVal ? await this.getAvailableStudents() : ''
    },
  },
  methods: {
    ...mapActions('docOrdersStudents', ['GET_SECTION_STUDENTS', 'CREATE_STIPEND', 'POST_CREATE_ACADEMIC_MOBILITY', 'GET_STUDY_LEVELS',
      'GET_FOREIGN_UNIVERSITY', 'GET_EDUCATION_PROGRAMS', 'GET_STUDENTS_BY_EDUCATION_PROGRAM_ID',
      'POST_DOC_ORDER_STUDENTS', 'GET_STUDENT_GROUPS_BY_NAME', 'GET_EDUCATION_PROGRAMS_BY_NAME', 'CREATE_TRANSFER']),
    ...mapActions('formationEducationProgram', ['GET_EDUCATION_SEMESTER']),

    async sendStudentsForTransfer(event) {
      console.log('123')
      let data = {
        order_id: this.$route.query.order_id,
        sections_id: this.$route.query.sections_id,
        students: []
      }
      for (const student of this.checked_students) {
        data.students.push({student_id: Number(student)})
      }
      console.log(data)

      if (await this.CREATE_TRANSFER(data)) {
        this.$toast.add({severity: 'info', summary: 'Успешно', detail: 'Действие успешно выполнено!', life: 3000});
        this.checked_students = [];
        this.studyLevelId = 0;
        this.studyCourse = 0;
        this.educationProgramId = 0;
        this.groupId = 0;
        this.loadingGroup = false;
        this.filteredStudents = [];
        this.checked_students = [];
        this.selected_student = {};

        await this.GET_SECTION_STUDENTS(this.$route.query.sections_id)
      } else {
        this.$toast.add({severity: 'error', summary: 'Неудачно', detail: 'Что то пошло не так!', life: 3000});
      }
    },

    selectGroup() {
      this.filteredStudents = this.docOrdersStudents_form.students.filter(i => i.group_id == this.groupId)
      console.log(this.filteredStudents, 'filteredStudents')
    },

    clearStudents() {
      this.filteredStudents = []
      this.checked_students = []
    },

    getEducationPrograms() {
      if (this.studyLevelId && this.studyCourse) {
        this.GET_EDUCATION_PROGRAMS({studyLevelId: this.studyLevelId, studyCourse: this.studyCourse})
        this.clearStudents()
      }
    },


    async getAvailableStudents() {
      try {
        const {
          status,
          data
        } = await httpClient.get(`education_program/education-courses/get-student-data-and-courses?name=${this.searchData}`)

        if (status === 200) {
          if (data.success) {
            this.availableStudents = data.data
          }
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
    },

    async chooseStudent(student_id) {
      this.chosenStudents.push(this.availableStudents.find(i => i.student_id == student_id))
      this.availableStudents = []
      this.searchData = ''
    },

    removeFromStudentData(student_id) {
      this.chosenStudents = this.chosenStudents.filter(i => i.student_id !== student_id);
    },

    async confirm(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: 'В случае подтверждение, нельзя будет удалить выбранных студентов из приказа!',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          let data = {
            order_id: this.$route.query.order_id,
            sections_id: this.$route.query.sections_id,
            students: [],
            semester: this.chosenSemester,
            foreign_university_id: this.chosenUniversity
          }
          for (const student of this.chosenStudents) {
            data.students.push({student_id: student.student_id})
          }
          console.log(data)

          if (this.order_type_id == 11) {
            if (await this.POST_CREATE_ACADEMIC_MOBILITY(data)) {
              await this.handleSuccess(this.successMessage);
            } else {
              this.handleError(this.errorMessage);
            }
          } else {
            if (await this.CREATE_STIPEND(data)) {
              await this.handleSuccess(this.successMessage);
            } else {
              this.handleError(this.errorMessage);
            }
          }
        },
        reject: () => {
          this.handleError('Вы отклонили действие')
        }
      });
    },

    async handleSuccess(message) {
      this.$toast.add({severity: 'info', summary: 'Успешно', detail: message, life: 3000});
      this.chosenStudents = [];
      await this.GET_SECTION_STUDENTS(this.$route.query.sections_id);
    },

    handleError(message) {
      this.$toast.add({severity: 'error', summary: 'Неудачно', detail: message, life: 3000});
    },

    async changeCourse(){
      this.loadingChangeCourse = true
      try {
        const { status } = await httpClient.post(`doc_orders/doc-orders/change-course`, {
          order_id: this.$route.query.order_id,
          section_id: this.$route.query.sections_id
        })

        if( status === 200 ){
          await this.GET_SECTION_STUDENTS(this.$route.query.sections_id)
          this.$message({title: 'Сохранено'})
        }
      } catch (e) {
        this.$error({title: 'Ошибка'})
      }

      this.loadingChangeCourse = false
    }

  },

  async mounted() {
    await this.GET_SECTION_STUDENTS(this.$route.query.sections_id)
    await this.GET_STUDY_LEVELS()
    await this.GET_FOREIGN_UNIVERSITY()
    await this.GET_EDUCATION_SEMESTER();
    this.semesters = this.formationEducationProgram_form.educationSemesters.slice(0, 7)
    console.log(this.semesters);
    this.order_type_id = this.$route.query.order_type_id;
  }
}
</script>

<style lang="scss" scoped>

</style>
